import React, { useEffect, useRef } from 'react';
import styles from '../components/styles/service.module.css';
import SESimg from '../images/SES.png';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const SES = () => {
  const refs = useRef([]);
  refs.current = [];

  useEffect(() => {
    refs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, y: +60 },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: 'top bottom+=10',
            end: 'bottom 100',
            toggleActions: 'play pause complete reverse',
          },
        }
      );
    });
  });

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const technologyAreas = [
    {
      catergory: 'インフラ',
      subcatergories: [
        {
          title: 'サーバー仮想化環境',
          content: 'VMware、Hyper-V、Citrix、KVM',
        },
        {
          title: 'クラウド環境',
          content: 'AWS、Azure、GCP',
        },
        {
          title: 'OS/ミドルウェア環境',
          content: 'Windows、Linux、Oracle、SQL、EMC、Tripwire、LanScopeCat',
        },
      ],
    },
    {
      catergory: '開発',
      subcatergories: [
        {
          title: '言語',
          content: 'Java、JavaScript、TypeScript、Python、Ruby、C、C++、C#',
        },
        {
          title: 'フレームワーク',
          content: 'Spring Boot、MyBatis、React',
        },
        {
          title: '環境',
          content:
            'Jest、jUnit/Mockito、Git、Jenkins、Restful API、Docker、Gatsby、Next.js',
        },
      ],
    },
  ];

  const developmentAchievements = [
    {
      title: '業種',
      content: <p>金融、サービス、メーカー、官公庁、保険</p>,
    },
    {
      title: '案件名',
      content: (
        <p>
          電子債権管理システム、社員端末基盤構築、顧客管理システムインフラ、社内システム運用、全文検索システム、コンテナ基盤作成支援
          etc...
        </p>
      ),
    },
    {
      title: '技術領域',
      content: (
        <ol className={styles.technologyArea}>
          {technologyAreas.map((technologyArea, i) => (
            <li key={`service-ses-technology-list-${i}`}>
              <p className={styles.technologyAreaCategory} ref={addToRefs}>
                {technologyArea.catergory}
              </p>
              {technologyArea.subcatergories.map((subcategory, i) => (
                <div ref={addToRefs} key={`service-ses-subcategory-${i}`}>
                  <p className={styles.technologyAreaSubcategoryTitle}>
                    {subcategory.title}
                  </p>
                  <p className={styles.technologyAreaSubcategoryContent}>
                    {subcategory.content}
                  </p>
                </div>
              ))}
            </li>
          ))}
        </ol>
      ),
    },
  ];

  return (
    <>
      <div className={styles.serviceContentContainer}>
        <div className={styles.serviceContent}>
          <div className={styles.topContainer}>
            <p className={styles.SESdescription} ref={addToRefs}>
              バークリー・ジャパンでは、通信インフラや、機械制御等の組み込みソフトウェア開発を含むインフラ技術から、スマートフォン対応のアプリケーションや、IoTシステム、事業系システムに至るまで幅広い分野の技術力を提供いたします。
            </p>
            <div className={styles.SESimg} ref={addToRefs}>
              <img src={SESimg} alt='SES画像' />
            </div>
          </div>
          <h2 className={styles.serviceContentTitle} ref={addToRefs}>
            開発実績
          </h2>
          <div className={styles.serviceContentDescription}>
            {developmentAchievements.map(({ title, content }, i) => (
              <div key={`service-ses-developmentCondition-${i}`}>
                <p className={styles.SESdevelopmentCondition} ref={addToRefs}>
                  {title}
                </p>
                <div
                  className={styles.SESdevelopmentConditionDetail}
                  ref={addToRefs}
                >
                  {content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
