import React, { useRef, useEffect } from 'react';
import buttonStyle from '../components/styles/button.module.css';
import styles from '../components/styles/service.module.css';
import myme1 from '../images/myme1.png';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const ApplicationDevelopmentContent = () => {
  const refs = useRef([]);
  refs.current = [];

  useEffect(() => {
    refs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, y: +60 },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: 'top bottom-=10',
            end: 'bottom 100',
            toggleActions: 'play pause complete reverse',
          },
        }
      );
    });
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const modelCases = [
    {
      title: 'テイクアウト予約管理システム',
      image: myme1,
      description:
        '飲食店のテイクアウト予約管理システムです。飲食店利用客は店舗のWebサイトから直接アクセスする、またはQRコードを読み取ることでメニューの閲覧やテイクアウト予約注文を行えます。\n飲食店スタッフは、Webアプリ上で、メニューのレイアウトや内容の変更やテイクアウト注文の管理が可能です。',
      link: 'https://www.varcly.co.jp/service/myme/',
    },
  ];

  return (
    <div className={styles.developmentContainer}>
      <div className={styles.developmentDescriptionContainer}>
        <div className={styles.webContainer}>
          <p className={styles.serviceContentSubtitle}>
            WEBアプリケーション開発
          </p>
          <p className={styles.webDescription}>
            Webアプリケーションとは、端末にソフトウェアをインストールする必要がなく、ブラウザへアクセスするだけで利用可能なサービスのことを指します。
            現在のWebアプリはネイティブアプリと比較しても動作速度に遜色なく、オフラインによる閲覧も実現可能です。
            バークリー・ジャパンでは、UI/UXを意識したウェブアプリの提供により、お客様のご要望とニーズに応えて参ります。
          </p>
        </div>
        <div className={styles.nativeContainer}>
          <p className={styles.serviceContentSubtitle}>
            ネイティブアプリケーション開発
          </p>
          <p className={styles.nativeDescription}>
            ネイティブアプリケーションとは、スマートフォンやPCにインストールして利用するアプリケーションです。
            様々な業界でサービスのIT化が進んでいる中で、モバイル端末の普及によりiPhone/iPadやAndroidに対応したアプリケーションの需要が高まっています。
            バークリー・ジャパンでは企画からデザイン・開発・申請まで一気通貫したサポートを提供いたします。
          </p>
        </div>
      </div>
      <div className={styles.modelCaseBorder} ref={addToRefs} />
      <p className={styles.serviceContentSubtitle} ref={addToRefs}>
        開発事例紹介
      </p>
      {modelCases.map((modelCase, i) => (
        <>
          {i !== 0 && <div className={styles.modelCaseBorder} />}
          <p className={styles.modelCaseTitle} ref={addToRefs}>
            {modelCase.title}
          </p>
          <div className={styles.modelCaseContainer} ref={addToRefs}>
            <div className={styles.modelCaseDescriptionContainer}>
              <p className={styles.modelCaseDescription}>
                {modelCase.description}
              </p>
              <a
                href={modelCase.link}
                className={buttonStyle.button}
                text-value='詳細はこちら'
                target='_blank'
                rel='noopener noreferrer'
              >
                紹介はこちら
              </a>
            </div>
            <a
              href={modelCase.link}
              className={styles.modelCaseImageContainer}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={modelCase.image} alt='飲食店電子メニューサービス' />
            </a>
          </div>
        </>
      ))}
    </div>
  );
};
