import React, { useState, useEffect, useRef } from 'react';
import ceceri1 from '../images/ceceri1.jpg';
import ceceri2 from '../images/ceceri2.jpg';
import ceceri3 from '../images/ceceri3.jpg';
import ceceriSp1 from '../images/ceceriSp1.jpg';
import ceceriSp2 from '../images/ceceriSp2.jpg';
import ceceriSp3 from '../images/ceceriSp3.jpg';
import mentre1 from '../images/mentre1.png';
import mentre2 from '../images/mentre2.png';
import mentre3 from '../images/mentre3.png';
import mentreSp1 from '../images/mentre-sp1.png';
import mentreSp2 from '../images/mentre-sp2.png';
import mentreSp3 from '../images/mentre-sp3.png';
import myme1 from '../images/myme1.png';
import myme2 from '../images/myme2.png';
import mymeSp1 from '../images/myme-sp1.png';
import styles from '../components/styles/service.module.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Modal from 'react-modal';

SwiperCore.use([Navigation]);
Modal.setAppElement('#___gatsby');

export const DevelopmentAchievementContent = () => {
  const refs = useRef([]);
  refs.current = [];

  const [picExpanded, setPicExpanded] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const clientWidth =
    typeof document !== `undefined` ? document.body.clientWidth : null;

  useEffect(() => {
    refs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, y: +60 },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: 'top-=200 bottom+=10',
            end: 'bottom 100',
            toggleActions: 'play pause complete reverse',
          },
        }
      );
    });
  }, []);

  SwiperCore.use([Pagination]);
  const swiperProps = {
    spaceBetween: 80,
    pagination: {
      clickable: true,
      dynamicBullets: true,
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    loop: true,
  };

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  const developmentAchievements = [
    {
      title: 'テイクアウト予約管理システム',
      detail:
        '飲食店のテイクアウト予約管理システムです。\n飲食店利用客は店舗のWebサイトから直接アクセスする、またはQRコードを読み取ることでメニューの閲覧やテイクアウト予約注文を行えます。\n飲食店スタッフは、Webアプリ上で、メニューのレイアウトや内容の変更やテイクアウト注文の管理が可能です。',
      images: clientWidth > 550 ? [myme1, myme2] : [mymeSp1],
      link: 'https://www.varcly.co.jp/service/myme/',
      rangeDevelopment:
        '要件定義、基本設計、詳細設計、製造、結合テスト、総合テスト、運用/保守',
      developmentLanguage: 'Gatsby.js、Node.js、TypeScript、PostgreSQL',
    },
    {
      title: '派遣会社向け勤怠システム',
      detail:
        'Webアプリケーションのため、インストール不要でPCやスマホから直接勤怠入力や勤務表の提出等が可能で、派遣ならではの「派遣先でエクセルが使えない」「勤務表を毎月送信するのが面倒」という問題を解決します。また、入力された勤怠時間によって自動計算された残業時間や控除時間などの項目がCSV形式で出力可能なため、給与計算システムへの外部連携も可能です。',
      images:
        clientWidth > 550
          ? [ceceri1, ceceri2, ceceri3]
          : [ceceriSp1, ceceriSp2, ceceriSp3],
      link: '',
      rangeDevelopment:
        '要件定義、基本設計、詳細設計、製造、結合テスト、総合テスト、運用/保守',
      developmentLanguage:
        'React、Next.js、TypeScript、Spring、Java、PostgreSQL',
    },
    {
      title: '自己診断システム',
      detail:
        '設問に答えることで、自分自身の考え方の特徴を診断・分析するシステムです。新規登録後、カード決済を行うことでテストを受けることができます。テスト結果の推移表の確認や、診断結果をPDFで出力することも可能です。',
      images:
        clientWidth > 550
          ? [mentre1, mentre2, mentre3]
          : [mentreSp1, mentreSp2, mentreSp3],
      link: '',
      rangeDevelopment:
        '要件定義、基本設計、詳細設計、製造、結合テスト、総合テスト、運用/保守',
      developmentLanguage: 'Firebase、Express、chart.js、Node.js、JavaScript',
    },
    {
      title: '画面共有サービスデモ',
      detail:
        'ビデオ通話をしながらプログラミング画面を共有できるサービスです。',
      images: '',
      link: '',
      rangeDevelopment: '',
      developmentLanguage:
        'React、Next.js、TypeScript、Spring、Java、PostgreSQL',
    },
    {
      title: '会議室予約アプリケーション',
      detail:
        '会議室の予約をカレンダー上で確認、管理できるシステムです。予約の登録・編集・削除が可能です。',
      images: '',
      link: '',
      rangeDevelopment: '',
      developmentLanguage:
        'React、Next.js、TypeScript、Spring、Java、PostgreSQL',
    },
  ];

  const modalStyles = {
    overlay: {
      zIndex: 101,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    content: {
      width: clientWidth > 550 ? '70vw' : '75vw',
      height: clientWidth > 550 ? '90vh' : '75vh',
      top: '50%',
      left: '50%',
      margin: '0',
      padding: '0',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      border: 'rgba(0,0,0,0)',
    },
  };

  const openModal = (index) => {
    setPicExpanded(index);
    setIsModalOpen(true);
    document.body.setAttribute('style', 'overflow: hidden;');
  };

  const closeModal = () => {
    setPicExpanded('');
    setIsModalOpen(false);
    document.body.removeAttribute('style', 'overflow: hidden;');
  };

  return (
    <>
      {developmentAchievements.map(
        (
          {
            title,
            detail,
            images,
            link,
            rangeDevelopment,
            developmentLanguage,
          },
          index
        ) => {
          return (
            <div key={`service-webService-developmentAchievement${index}`}>
              {index !== 0 && (
                <div
                  className={styles.developmentAchievementBorder}
                  ref={addToRefs}
                />
              )}
              <section
                className={
                  images
                    ? styles.developmentAchievementDescriptionSection
                    : styles.OnlyDevelopmentAchievementDescriptionSection
                }
              >
                <h2
                  className={styles.developmentAchievementSubtitle}
                  ref={addToRefs}
                >
                  {title}
                </h2>
                <div className={styles.descriptionAndImage}>
                  <p ref={addToRefs}>
                    {detail}
                    <br />
                    {link && (
                      <a href={link} className={styles.detailLink}>
                        詳細はこちら ▶︎
                      </a>
                    )}
                  </p>
                  {!!images && (
                    <div className={styles.thumnailConatiner}>
                      <img
                        src={Array.isArray(images) ? images[0] : images}
                        alt=''
                        className={styles.developmentAchievementThumbnail}
                        role='presentation'
                        onClick={() => openModal(index)}
                        ref={addToRefs}
                      />
                      {images.length !== 1 && (
                        <div
                          className={
                            clientWidth < 550
                              ? styles.moreImagesForSp
                              : styles.moreImagesForPc
                          }
                          role='presentation'
                          onClick={() => openModal(index)}
                        >
                          MORE IMAGES ▶︎
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {picExpanded !== '' && picExpanded === index && (
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    style={modalStyles}
                  >
                    <Swiper className={styles.swiper} {...swiperProps}>
                      {!Array.isArray(
                        developmentAchievements[picExpanded].images
                      ) ||
                      developmentAchievements[picExpanded].images.length ===
                        1 ? (
                        <SwiperSlide className={styles.swiperSlide}>
                          <img
                            src={developmentAchievements[picExpanded].images}
                            alt=''
                          />
                        </SwiperSlide>
                      ) : (
                        developmentAchievements[picExpanded].images.map(
                          (image, index) => (
                            <SwiperSlide
                              className={styles.swiperSlide}
                              key={`service-webService-developmentAchievementImage${index}`}
                            >
                              <img src={image} alt='' />
                            </SwiperSlide>
                          )
                        )
                      )}
                      {Array.isArray(
                        developmentAchievements[picExpanded].images
                      ) &&
                        developmentAchievements[picExpanded].images.length !==
                          1 && (
                          <>
                            <div
                              className={`swiper-button-prev  swiper-button-black ${styles.prevButton}`}
                            />
                            <div
                              className={`swiper-button-next  swiper-button-black ${styles.nextButton}`}
                            />
                            <div
                              className={`swiper-pagination ${styles.pagenation}`}
                            />
                          </>
                        )}
                    </Swiper>
                  </Modal>
                )}
              </section>
              <section className={styles.developmentConditionSection}>
                {!!rangeDevelopment && (
                  <>
                    <p className={styles.developmentCondition} ref={addToRefs}>
                      作業範囲
                    </p>
                    <p
                      className={styles.developmentConditionDetail}
                      ref={addToRefs}
                    >
                      {rangeDevelopment}
                    </p>
                  </>
                )}
                <p className={styles.developmentCondition} ref={addToRefs}>
                  開発言語
                </p>
                <p
                  className={styles.developmentConditionDetail}
                  ref={addToRefs}
                >
                  {developmentLanguage}
                </p>
              </section>
            </div>
          );
        }
      )}
    </>
  );
};
