import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import { Subheader } from '../../components/Subheader.jsx';
import { ToTopButton } from '../../components/ToTopButton.jsx';
import { HeaderImage } from '../../components/HeaderImage.jsx';
import { WebService } from '../../components/WebService.jsx';
import { SES } from '../../components/SES.jsx';
import styles from '../../components/styles/service.module.css';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Service = () => {
  const [picExpanded, setPicExpanded] = useState();

  const refs = useRef([]);
  refs.current = [];

  useEffect(() => {
    refs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, y: +60 },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: 'top bottom-=50',
            end: 'bottom 100',
            toggleActions: 'play pause complete reverse',
          },
        }
      );
    });
  }, []);

  const subheaderProps = {
    subheaderList: [
      { title: 'WEBサービス事業 ▼', link: '#webService' },
      { title: 'SES事業 ▼', link: '#SES' },
      //TODO: リリース後挿し込む
      // { title: '代理販売事業 ▼', link: '#agentSales' },
    ],
    style: styles.subheader,
  };

  const serviceContents = [
    {
      boxTd: 'webService',
      title: 'WEB SERVICE',
      subTitle: 'WEBサービス事業',
      content: WebService(picExpanded, setPicExpanded),
    },
    {
      boxTd: 'SES',
      title: 'SES',
      subTitle: 'SES事業',
      content: SES(),
    },
    //TODO: リリース後挿し込む
    // {
    //   boxTd: 'agentSales',
    //   title: 'AGENT SALES',
    //   subTitle: '代理販売事業',
    //   content: '',
    // },
  ];

  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  return (
    <Layout>
      <SEO title='事業内容' description='Webアプリケーションを主軸に受託開発、社内開発、SESを事業として展開しています。'/>
      <HeaderImage title='SERVICE' subTitle='事業内容' fileName='service.jpg' />
      <ToTopButton />
      <Subheader subheaderProps={subheaderProps} />
      {serviceContents.map(({ boxTd, title, subTitle, content }, i) => (
        <div className={styles.servicePageContent} key={`service-${i}`}>
          <section
            id={boxTd}
            className={styles.contentContainer}
            key={`service-container-${i}`}
            ref={addToRefs}
          >
            <h1
              className={styles.title}
              text-value={title}
              key={`service-title-${i}`}
            >
              {title}
            </h1>
            <h2 className={styles.subTitle} key={`service-subtitle-${i}`}>
              {subTitle}
            </h2>
            <div
              className={styles.boxContentContainer}
              key={`service-boxcontainer-${i}`}
            >
              <section
                className={styles.boxContent}
                key={`service-boxcontent-${i}`}
              >
                {content}
              </section>
            </div>
          </section>
        </div>
      ))}
    </Layout>
  );
};

export default Service;
