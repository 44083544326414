import React, { useEffect, useRef } from 'react';
import { DevelopmentAchievementContent } from '../components/DevelopmentAchievementContent.jsx';
import { ApplicationDevelopmentContent } from '../components/ApplicationDevelopmentContent.jsx';
import styles from '../components/styles/service.module.css';
import consultingImg from '../images/consulting.png';


import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const WebService = () => {
  const consultingContent = () => (
    <div className={styles.consultingContainer}>
      <p>
        社内向けサービスや、顧客向けサービスに対して、WEBを活用したコンサルティングを実施いたします。
        昨今では、IT無くして企業の経営・業務は成り立たないほどITの重要性は非常に高まっています。
        私たちは、お客様の「ニーズ」を的確にとりまとめ、最新の技術を効果的に活用することにより、「課題」や「問題点」を迅速に解決していきます。今までITを導入したことのないお客様にも安心していただけるよう、弊社がサポートいたしますので、どんな些細なことでもお気軽にご相談ください。
      </p>
      <img src={consultingImg} alt='コンサルティング画像' />
    </div>
  );

  const infrastructureContent = () => (
    <>
      <p>
        Webサービスにおけるインフラ基盤の構築についても親会社とのアライアンスにより提供が可能です。親会社である日創工業株式会社・ITソリューション事業部では、AWS/Azureを主軸としたクラウド基盤環境の設計構築サービス、並びにネットワーク・セキュリティ分野や各種ミドルウェアの設計構築など、さまざまな技術サービスのご提供を展開させて頂いております。
      </p>
      <a
        className={styles.linkToNisso}
        href='https://www.nisso-ind.co.jp/'
        target='_blank'
        rel='noopener noreferrer'
      >
        日創工業株式会社のサイトはこちら ▶
      </a>
    </>
  );

  const webServiceContentsProps = [
    {
      title: 'コンサルティング事業',
      content: consultingContent(),
    },

    {
      title: 'アプリケーション開発',
      // 大きいので外に出しています↓↓↓
      content: ApplicationDevelopmentContent(),
    },

    {
      title: 'インフラ基盤',
      content: infrastructureContent(),
    },

    {
      title: '社内開発実績',
      // 大きいので外に出しています↓↓↓
      content: DevelopmentAchievementContent(),
    },
  ];

  return (
    <>
      {webServiceContentsProps.map((webServiceContentProps, i) => (
        <WebServiceContent
          webServiceContentProps={webServiceContentProps}
          key={`web-service-${i}`}
        />
      ))}
    </>
  );
};

const WebServiceContent = ({ webServiceContentProps }, key) => {
  const { title, content } = webServiceContentProps;

  gsap.registerPlugin(ScrollTrigger);

  const refs = useRef([]);
  refs.current = [];

  const addToRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  useEffect(() => {
    refs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        { autoAlpha: 0, y: +60 },
        {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: 'top bottom-=10',
            end: 'bottom 100',
            toggleActions: 'play pause complete reverse',
          },
        }
      );
    });
  }, []);

  return (
    <div className={styles.serviceContentContainer} ref={addToRefs} key={key}>
      <div className={styles.serviceContent}>
        <h2 className={styles.serviceContentTitle} ref={addToRefs}>
          {title}
        </h2>
        <div className={styles.serviceContentDescription} ref={addToRefs}>
          {content}
        </div>
      </div>
    </div>
  );
};
